import React, { useContext } from "react";

import { Link } from 'gatsby'
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useI18next } from 'gatsby-plugin-react-i18next'

import { BtnArrow } from "../custom/elements";

const NotFound = ({ data }) => {

  const {
    translateUrl // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)

  const { language } = useI18next()

  return (
    <div className="text-center m-auto full-width p-absolute container-not-found ">
      <div className="white ISemiBold  m-auto text-center">
        <Link to={translateUrl('/', language)} className="d-inline-flex link-hover-blue vertical-center" >
          <BtnArrow inverse />
          <p className="blue fs-6 ILightBeta uppercase">{data.back}</p>
        </Link>
      </div>
    </div >
  );
}

export default NotFound;