import * as  React from "react";

export const BtnArrow = ({ inverse }) => {

    if (inverse)
        return (<div className="arrow-up-no-bg"
            style={{
                transform: 'rotate(270deg)'
            }}>
            <span className="left-arm"></span>
            <span className="right-arm"></span>
            <span className="arrow-body"></span>
            <span className="arrow-slide"></span>
        </div>)
    else if (!inverse)
        return (<div className="arrow-up-no-bg">
            <span className="left-arm"></span>
            <span className="right-arm"></span>
            <span className="arrow-body"></span>
            <span className="arrow-slide"></span>
        </div>)
}